import React, { useState } from 'react'

import { H1Light, TextBody1 } from 'components/atoms/Typography'

import { Breadcrumbs } from 'components/modules/Breadcrumbs'
import { CardType, NewsCard } from 'components/modules/NewsCard'

import { Pagination } from 'components/atoms/Pagination'
import useBreakpoint from 'hooks/useBreakpoint'

import {
  Wrapper,
  InnerWrapper,
  BreadcrumbsContainer,
  NewsCardsOuterWrapper,
  NewsCardsInnerWrapper,
  PaginationContainer,
} from './News.styles'

type Props = {
  heading: string
  cards: CardType[]
}

export const News: React.FC<Props> = ({ heading, cards }) => {
  const { lg } = useBreakpoint()

  const itemsPerPage = lg ? 6 : 3

  const [currentPage, setCurrentPage] = useState(1)
  const numberOfPages = Math.ceil(cards.length / itemsPerPage)

  const onPageChange = (pageNumber: number) => {
    const targetSection = document.getElementById('aktualnosci')
    if (targetSection) {
      const { top } = targetSection.getBoundingClientRect()
      window.scrollTo({
        top: top + window.pageYOffset - 40,
        behavior: 'smooth',
      })
    }
    setCurrentPage(pageNumber)
  }

  const pageCards = [
    ...cards.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    ),
  ]

  return (
    <Wrapper>
      <H1Light dangerouslySetInnerHTML={{ __html: heading }} align="center" />

      <InnerWrapper id="aktualnosci">
        <BreadcrumbsContainer margin={[0, 'auto']} padding={[0, 120]}>
          <Breadcrumbs crumbs={[{ name: 'Aktualności' }]} />
        </BreadcrumbsContainer>

        <NewsCardsOuterWrapper>
          <NewsCardsInnerWrapper>
            {pageCards?.map((card, index) => (
              <NewsCard
                cardProps={card}
                autoWidth
                key={`${card.heading}-${index}`}
              />
            ))}
          </NewsCardsInnerWrapper>
          <PaginationContainer>
            <TextBody1 color="gray60">Strona</TextBody1>
            <Pagination
              numberOfPages={numberOfPages}
              currentPage={currentPage}
              onPageChange={onPageChange}
            />
          </PaginationContainer>
        </NewsCardsOuterWrapper>
      </InnerWrapper>
    </Wrapper>
  )
}
