import styled from 'styled-components'
import media from 'styles/media'

import { H1Light } from 'components/atoms/Typography'
import { Container } from 'components/atoms/Container'
import { Wrapper as BreadcrumbsWrapper } from 'components/modules/Breadcrumbs/Breadcrumbs.styles'

export const Wrapper = styled.div`
  margin-top: 112px;

  ${H1Light} {
    padding: 72px 24px;
  }

  ${media.sm.max} {
    margin-top: 56px;

    ${H1Light} {
      padding: 48px 24px;
    }
  }
`

export const InnerWrapper = styled.div``

export const BreadcrumbsContainer = styled(Container)`
  max-width: calc(1136px + 240px);

  ${BreadcrumbsWrapper} {
    border: none;
    border-top: 1px solid ${({ theme }) => theme.colors.gray05};
  }

  ${media.md.max} {
    padding: 0 24px;
  }
  ${media.sm.max} {
    padding: 0;
  }
`

export const NewsCardsOuterWrapper = styled.div`
  background-color: #f3f3f3;
  padding: 100px 120px;

  ${media.md.max} {
    padding: 48px 24px;
  }
`

export const NewsCardsInnerWrapper = styled.div`
  max-width: 1136px;

  width: fit-content;

  margin: 0px auto;

  display: grid;
  grid-template-rows: repeat(auto, 1fr);
  grid-template-columns: repeat(3, 1fr);
  row-gap: 40px;
  column-gap: 40px;

  ${media.xxl.max} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${media.lg.max} {
    row-gap: 16px;
    grid-template-columns: repeat(1, 1fr);
  }
`

export const PaginationContainer = styled.div`
  max-width: 1136px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;

  gap: 16px;

  align-items: center;

  ${media.lg.max} {
    margin-top: 24px;
  }
`
